<template>
  <component :is="component" :status="$route.meta.status"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'QuoteListManager',

  components: {
    BuyerQuoteView: () => import('./BuyerQuoteView'),
    VendorQuoteView: () => import('./VendorQuoteView'),
    SuperQuoteView: () => import('./SuperQuoteView'),
    SuperVendorQuoteView: () => import('./SuperVendorQuoteView'),
  },

  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          if (this.$route.query.view_quote) {
            return 'SuperVendorQuoteView';
          }

          return 'SuperQuoteView';
        case PersonaConstants.vendor.value:
          return 'VendorQuoteView';
        case PersonaConstants.buyer.value:
        default:
          return 'BuyerQuoteView';
      }
    },
  },
};
</script>
